import React from 'react';
import { Grid2, Typography } from '@mui/material';

import CopyLinkButton from 'core/libs/core-ui/components/CopyLinkButton';

import ExperienceHeader from '../ExperienceHeader';
import ExperiencePolicies from '../ExperiencePolicies';
import MediaContainer from '../MediaContainer';
import PageIndicator from '../PageIndicator';

import useStyles from './ExperienceGrid.styles';

const ExperienceGrid = ({
    experience,
    contractCode,
    contractName,
    mediaUrls,
    useTranslation,
    useSliderIndex,
    handleOpenInFullScreen,
}) => {
    const classes = useStyles();
    const { t: translate, i18n } = useTranslation();
    const i18nLang = i18n.language;

    return (
        <Grid2 container spacing={2} className={classes.gridDetailsWrap}>
            <Grid2 size={{xs: 12}}>
                <MediaContainer
                    mediaUrls={mediaUrls}
                    sliderIndex={useSliderIndex.sliderIndex}
                    setSliderIndex={useSliderIndex.setSliderIndex}
                    handleOpenInFullScreen={handleOpenInFullScreen}
                />
            </Grid2>
            <Grid2 size={{xs: 12}}>
                <Grid2 container justifyContent="space-between" alignItems="center">
                    <Grid2>
                        <PageIndicator
                            pageStack={[
                                ...(contractCode ?
                                    [{ name: contractName, link: `/qr?code=${contractCode}` }] :
                                    [{ name: "uTriper Home", link: "/" },]),
                                { name: experience.name },
                            ]}
                        />
                    </Grid2>
                    <Grid2 className={classes.copyLinkButton}>
                        <CopyLinkButton
                            experienceId={experience.id}
                            experienceName={experience.name}
                            contractCode={contractCode}
                            label={translate(
                                'libs.buttons.copylink'
                            )}
                        />
                    </Grid2>
                </Grid2>
            </Grid2>
            <Grid2 size={{xs: 12}}>
                <ExperienceHeader
                    experience={experience}
                    useTranslation={useTranslation}
                />
            </Grid2>
            <Grid2 size={{xs: 12}}>
                <ExperiencePolicies
                    experience={experience}
                    lang={i18nLang}
                    translate={translate}
                />
            </Grid2>
            <Grid2 size={{xs: 12}} className={classes.experienceDescription}>
                <Typography>{experience.description}</Typography>
            </Grid2>
        </Grid2>
    );
};

export default ExperienceGrid;
