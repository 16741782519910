import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@mui/material';

import AvailabilityCalendar from 'core/libs/core-ui/components/AvailabilityCalendar';
import { useTranslation } from 'core/contexts/localization';

import useStyles from './BookingButton.styles';

const BookingButton = ({
    showCalendar,
    experience,
    contractCode
}) => {
    const { t: translate } = useTranslation();
    const history = useHistory();
    const classes = useStyles();

    return showCalendar ? (
        <AvailabilityCalendar initialExperience={experience} code={contractCode} />
    ) : (
        <Button
            onClick={() =>
                history.push(
                    `/booking/new/${experience.id}${contractCode ? '/code/' + contractCode : ''
                    }`
                )
            }
            className={classes.buttonBook}>
            {translate('experienceCard.book')}
        </Button>
    );
};

export default BookingButton;
