import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    buttonsOverlay: {
        position: "relative",
        width: "100%",
        height: 0,
    },
    fullScreenImageButton: {
        position: "absolute",
        zIndex: 150,
        top: "1rem",
        right: "0.5rem",
        color: theme.palette.colors.white,
        background:
            "radial-gradient(circle, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0) 75%)",
    },
    imageButton: {
        position: "absolute",
        zIndex: 150,
        top: "11rem",
        color: theme.palette.colors.white,
        background:
            "radial-gradient(circle, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0) 75%)",
    },
    imageLeftButton: {
        left: 0,
        "&:active": {
            boxShadow: "none",
        },
        "&:hover": {
            boxShadow: "none",
        },
        "&:focus": {
            boxShadow: "none",
        },
    },
    imageRightButton: {
        right: 0,
        "&:active": {
            boxShadow: "none",
        },
        "&:hover": {
            boxShadow: "none",
        },
        "&:focus": {
            boxShadow: "none",
        },
    },
    mainBox: {
        position: "relative",
        height: 0,
        paddingBottom: "56.25%",
        borderRadius: "15px",
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        minHeight: "20rem",
    },
    imageStyle: {
        width: "100%",
        objectFit: "cover",
        alignSelf: "start",
    },
    iframeStyle: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "transparent",
        maxWidth: "100%",
    },
}));

export default useStyles;
