import makeStyles from '@mui/styles/makeStyles';
import palette from 'core/libs/core-ui/theme/colors-palette';

const useStyles = makeStyles((theme) => ({
    modusChip: {
        margin: "0.3rem",
        fontSize: "0.7rem",
    },
    experienceRecycle: {
        paddingLeft: "0rem",
        paddingRight: "0rem",
        paddingTop: "0rem",
        paddingBottom: "0rem",
    },
    experienceCancellation: {
        paddingLeft: "0.1rem",
        paddingRight: "0rem",
        paddingTop: "0rem",
        paddingBottom: "0rem",
    },
    experienceInclude: {
        paddingLeft: "0rem",
        paddingRight: "0rem",
        paddingTop: "0rem",
        paddingBottom: "0rem",
    },
    experienceRecycleWrapper: {
        display: "flex",
    },
    experienceIncludeWrapper: {
        display: "flex",
    },
    experienceCancellationWrapper: {
        padding: "0.4rem",
    },
    experienceCancellationContainer: {
        marginTop: "0.5rem",
    },
}));

export default useStyles;
