import React from 'react';
import { useParams } from 'react-router-dom';

import { AvailabilityCalendar } from '@utriper/core-ui';

import useStyles from './ViajeroBooking.styles';

import { ExperienceService } from '../../core/api/services/experiences';
import { ScheduleService } from '../../core/api/services/schedule';
import { useExperiences } from '../../core/contexts/experience';

const ViajeroBooking = () => {
    const { id, code } = useParams();
    const classes = useStyles();

    const { addBookedExperienceWithQuantityPrice, setTimeOfArrival } = useExperiences();

    return (
        <div className={classes.wrapper}>
            <AvailabilityCalendar experienceId={id} code={code} fullScreenMode={true} getById={ExperienceService.getById} getAvailability={ScheduleService.getAvailability} addBookedExperienceWithQuantityPrice={addBookedExperienceWithQuantityPrice} setTimeOfArrival={setTimeOfArrival} />
        </div>
    );
};

export default ViajeroBooking;
