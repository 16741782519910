export const ROUTES = {
    LANDING: '/',
    // LANDINGHOSTEL: '/hostel',
    BOOKING: '/booking/new/:id',
    BOOKING_CODE: '/booking/new/:id/code/:code',
    CHECKOUT: '/checkout',
    CHECKOUT_VALIDATION: '/checkout/validation',
    // SAVEDEXPERIENCES: '/saved-experiences',
    QR: '/qr',
    EXPERIENCE: '/experience',
    PURCHASE: '/purchase',
    ERROR: '/error',
    PENDING: '/pending',
    CHECKIN: '/checkin',
};
