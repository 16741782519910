import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    text: {
        display: 'flex',
        justifyContent: 'center',
    },
    container: {
        [theme.breakpoints.down('lg')]: {
            width: '85%',
        },
        [theme.breakpoints.up('md')]: {
            width: '55%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '45%',
        },
    },
    buttonStyle: {
        marginLeft: '15vw',
        marginTop: '3vh',
        backgroundColor: theme.palette.lightblue,
        color: 'white',
    },
}));
