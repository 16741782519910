import makeStyles from '@mui/styles/makeStyles';
import palette from 'core/libs/core-ui/theme/colors-palette';

const useStyles = makeStyles((theme) => ({
    buttonBook: {
        display: "flex",
        width: "100%",
        background: palette.utriper.lightblue,
        fontWeight: "bold",
        color: "white",
        "&:hover": {
            backgroundColor: palette.utriper.darkblue,
        },
    },
}));

export default useStyles;
