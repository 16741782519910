import React, { useState, useEffect } from 'react';
import { SortByPrice } from 'components/Sort/index';
import { useHostel } from 'core/contexts/hostel';
import { useExperiences } from 'core/contexts/experience';
import { useSorts } from 'core/contexts/sort';
import { useTranslation } from 'core/contexts/localization';

import { Grid2, Typography, useMediaQuery } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';

import { ModusSelect } from 'components/ModusSelect';
import { TourOperatorSelect } from 'components/TourOperatorSelect';
import { useFilters } from 'core/contexts/filter';
import { Box } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import { NumberParam, useQueryParam } from 'use-query-params';

const useStyles = makeStyles((theme) => ({
    container: {
        top: 0,
        marginTop: '10vh',
        marginLeft: '10vw',
        marginRight: '10vw',
    },
    imageWrapper: {
        width: '10rem',
        height: '10rem',
        borderRadius: '50%',
        objectFit: 'cover',
        display: 'flex',
        marginInline: 'auto',
    },
    locationWrapper: {
        textAlign: 'center',
        fontSize: '1rem',
        marginBottom: '1rem',
        paddingInline: '2rem',
    },
    nameWrapper: {
        textAlign: 'center',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        marginTop: '1rem',
        marginBottom: '0.3rem',
    },
    routeLineWrapper: {
        display: 'flex',
    },
    routeLine: {
        fontSize: '1.4rem',
        marginLeft: '1rem',
        display: 'inline-flex',
    },
    filersContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        paddingTop: '2rem',
        gap: '0.5rem',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            gap: '1.2rem',
        },
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            gap: '1.2rem',
        },
    },
    sectionChip: {
        boxShadow: '1px 1px gray',
        fontWeight: 500,
    },
    defaultSectionChip: {
        backgroundColor: '#A6ba62',
        color: 'white',
        boxShadow: '2px 2px green',
        fontWeight: 500,
    },
    sectionImageWrapper: {
        width: '4.5rem',
        height: '4.5rem',
        borderRadius: '50%',
        objectFit: 'cover',
        display: 'flex',
        marginInline: 'auto',
        border: '6px solid transparent',
    },
    chosenSectionImageWrapper: {
        width: '4.5rem',
        height: '4.5rem',
        borderRadius: '50%',
        objectFit: 'cover',
        display: 'flex',
        marginInline: 'auto',
        border: '4px solid #A6ba62',
        padding: '2px',
        // padding: '-2px'
    },
}));

export default function SimpleSelect() {
    const { t: translate } = useTranslation();
    const classes = useStyles();
    const isXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const isSm = useMediaQuery((theme) => theme.breakpoints.down('lg'));

    const { code, data, sectionId, setSectionId } = useHostel();
    // const { getHostelExperiences } = useExperiences();
    // const { priceOrder } = useSorts();
    // const { moduses } = useFilters();

    // useEffect(() => {
    //     if (code != null) {
    //         getHostelExperiences(code, priceOrder, moduses);
    //     }
    // }, [priceOrder, moduses])

    // const [chosenSectionId, setChosenSectionId] = useState(sectionId || null)

    const [URLSectionId, setURLSectionId] = useQueryParam('sectionId', NumberParam);

    const handleSectionChange = (id) => {
        if (id === sectionId) {
            setURLSectionId(null);
            setSectionId(null);
        } else {
            setURLSectionId(id);
            setSectionId(id);
        }
    };

    console.log({ sectionId });

    return (
        <>
            <Box className={classes.container}>
                <Grid2 container>
                    <Grid2 size={12}>
                        <Box>
                            <img className={classes.imageWrapper} src={data?.pictureURL} alt="" />
                            <Typography variant="h2" className={classes.nameWrapper}>
                                {data?.name}
                            </Typography>
                            <Typography variant="h2" className={classes.locationWrapper}>
                                {data?.location}
                            </Typography>
                        </Box>
                    </Grid2>

                    <Grid2
                        container
                        display={'flex'}
                        sx={{
                            overflowX: 'auto',
                            marginRight: '-2.25rem',
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                        }}
                        wrap="nowrap"
                        gap={4}>
                        {data?.sections.length > 0 &&
                            data?.sections.map((section) => (
                                <Grid2 onClick={() => handleSectionChange(section.id)} item minWidth={'4.5rem'} alignItems={'center'} textAlign={'center'} sx={{ width: '5.5rem' }}>
                                    <img className={sectionId === section.id ? classes.chosenSectionImageWrapper : classes.sectionImageWrapper} src={`${process.env.REACT_APP_API_URL}${section?.iconURL}`} alt="" />

                                    <span style={{ fontWeight: sectionId === section.id ? '500' : '400' }}>{section?.name}</span>
                                </Grid2>
                            ))}
                    </Grid2>
                    <Grid2 alignItems={isXs || isSm ? 'flex-start' : 'flex-end'} justifyContent={isXs || isSm ? 'flex-start' : 'flex-end'} size={12} className={classes.filersContainer} spacing={6}>
                        <Grid2 size={12} container display={'flex'} flexDirection={'row'} gap={2} justifyContent={'end'}>
                            <Box
                                sx={{
                                    display: 'flex',
                                }}>
                                <Box
                                    sx={{
                                        marginRight: '8px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                    }}></Box>
                                <SortByPrice />
                            </Box>

                            <Box sx={{ display: 'flex' }}>
                                <Box
                                    sx={{
                                        marginRight: '8px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}></Box>
                                <Box sx={{ width: 'auto' }}>
                                    <ModusSelect />
                                </Box>
                            </Box>
                        </Grid2>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ width: '9rem', display: 'grid' }}>
                                <TourOperatorSelect />
                            </Box>
                        </Box>
                    </Grid2>
                </Grid2>
            </Box>
        </>
    );
}
