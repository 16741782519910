import { useEffect } from 'react';
import { useTranslation } from 'core/contexts/localization';
import { useQueryParam, StringParam } from 'use-query-params';
import SortButton from './Button';
import { useSorts } from 'core/contexts/sort';
import { Order } from 'core/contexts/sort';


import sortIcon from 'assets/icons/sort-vertical.svg';

export const SortByPrice = ({ style }) => {
    const { t: translate } = useTranslation();

    const {
        setAscendingPriceOrder,
        setDescendingPriceOrder,
        priceOrder,
        isPriceOrderAscendant,
    } = useSorts();
    const [URLSort, setURLSort] = useQueryParam('sort', StringParam);

    useEffect(() => {
        if (URLSort === 'ASC') {
            setAscendingPriceOrder();
        } else if (URLSort === 'DESC') {
            setDescendingPriceOrder();
        } else {
            setAscendingPriceOrder();
        }
    }, []);

    return (
        <SortButton
            style={{
                order: '1px solid #c5c6c7',
                borderRadius: '25%',
                // height: '42px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                background: '#f9f9f9',
                // padding: '2px 4px',
            }}
            // style={style}
            text={<img src={sortIcon} alt="Sort" width={25} height={20} />}
            orderBy={priceOrder}
            onClick={() => {
                if (isPriceOrderAscendant()) {
                    setDescendingPriceOrder();
                    setURLSort('DESC');
                } else {
                    setAscendingPriceOrder();
                    setURLSort('ASC');
                }
            }}
        />
    );
};
