import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    experiencieTitle: {
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
        maxWidth: "25rem",
    },
    experiencieSubtitle: {
        marginBottom: "0.5rem",
    },
    experiencerName: {
        display: "inline",
        marginLeft: "0.3rem",
    },
    locationWrapper: {
        display: "flex",
        alignItems: "center",
    },
    perPersonWrapper: {
        marginLeft: "0.2rem",
        display: "inline",
    },
}));
