import React from 'react';

import { Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import {
    moods,
    getTranslatedName,
    isIdPresent,
} from 'core/libs/import/Moods';
import ModusChip from 'core/libs/core-ui/components/Chip';

import useStyles from './ModusList.styles';

const ModusList = ({ experience, lang }) => {
    const classes = useStyles();

    if (!experience || !experience.moduses) {
        return;
    }

    return (experience.moduses.some((m) => isIdPresent(m, moods)) && (
        <Box
            sx={{mb: 1}}
            flexWrap="nowrap"
            display="flex">
            <Box className={classes.checkIcon}>
                <CheckIcon />
            </Box>
            <Box
                className={classes.modusesWrapper}>
                {experience.moduses.map((modusId) => {
                    if (!isIdPresent(
                        modusId,
                        moods
                    )) {
                        return null;
                    }

                    return (<ModusChip
                        key={modusId}
                        color="gray"
                        className={classes.modusChip}
                        label={getTranslatedName(
                            modusId,
                            lang ?? 'en',
                            moods
                        )}
                    />);
                })}
            </Box>
        </Box>
    ));
};

export default ModusList;
