import React from 'react';

import { Box, IconButton } from '@mui/material';
import FullScreenIcon from '@mui/icons-material/ZoomOutMap';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

import useStyles from './MediaContainer.styles';

const MediaContainer = ({
    mediaUrls,
    sliderIndex,
    setSliderIndex,
    handleOpenInFullScreen
}) => {
    const classes = useStyles();

    const getSliderType = () => {
        return mediaUrls[sliderIndex].type;
    };

    const getSliderSrc = () => {
        return mediaUrls[sliderIndex].src;
    };

    const sliderNext = () => {
        if (sliderIndex === mediaUrls.length - 1) {
            setSliderIndex(0);
        } else {
            setSliderIndex(sliderIndex + 1);
        }
    };

    const sliderPrev = () => {
        if (sliderIndex === 0) {
            setSliderIndex(mediaUrls.length - 1);
        } else {
            setSliderIndex(sliderIndex - 1);
        }
    };

    return (<>
        <Box className={classes.buttonsOverlay}>
            <IconButton
                size="medium"
                onClick={handleOpenInFullScreen}
                className={classes.fullScreenImageButton}>
                <FullScreenIcon fontSize="small" />
            </IconButton>
            <IconButton onClick={handleOpenInFullScreen} size="large">
            </IconButton>
            <IconButton
                onClick={sliderPrev}
                size="medium"
                className={` ${classes.imageButton} ${classes.imageRightButton}`}>
                <ChevronRight fontSize="large" />
            </IconButton>
            <IconButton
                onClick={sliderNext}
                size="medium"
                className={` ${classes.imageButton} ${classes.imageLeftButton}`}>
                <ChevronLeft fontSize="large" />
            </IconButton>
        </Box>
        <Box className={classes.mainBox}>
            {getSliderType() === 1 ? (
                <img
                    className={classes.imageStyle}
                    src={getSliderSrc()}
                    alt={mediaUrls[sliderIndex].alt} />
            ) : (
                <iframe
                    className={classes.iframeStyle}
                    src={getSliderSrc()}
                    title="YouTube video player"
                    frameborder="0"
                    allow="fullscreen"></iframe>
            )}
        </Box>
    </>);
};

export default MediaContainer;
