import { useState, useEffect } from 'react';
import clsx from 'clsx';

import { ExperienceService } from 'core/api/services/experiences';
import { ScheduleService } from 'core/api/services/schedule';
import { useExperiences } from 'core/contexts/experience';
import ExperienceAvailabilityPageComponent from 'core/libs/core-ui/components/ExperienceAvailabilityPage';

import DialogCore from './components/dialog';
import ExperienceDetails from './components/experienceDetails';

import useStyles from './AvailabilityCalendar.styles';

const AvailabilityCalendar = ({
    initialExperience,
    experienceId,
    code = null,
    fullScreenMode = false,
}) => {
    const {
        addBookedExperienceWithQuantityPrice,
        setTimeOfArrival,
    } = useExperiences();

    const [loading, setLoading] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [datePicker, setDatePicker] = useState(new Date());
    const [experience, setExperience] = useState(initialExperience ?? {});
    const [visibleRange, setVisibleRange] = useState({ start: null, end: null });

    const [slots, setSlots] = useState();

    const [isScrolled, setIsScrolled] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    const classes = useStyles();

    useEffect(() => {
        const handleScroll = () => {
            if (!isScrolled && window.scrollY > 28) {
                setIsScrolled(true);
            } else if (isScrolled && window.scrollY <= 28) {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isScrolled]);

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        if (!experienceId) {
            return;
        }

        ExperienceService.getById(experienceId).then(setExperience);
    }, [experienceId]);

    useEffect(() => {
        if (!experience) {
            return;
        }

        if (Date.parse(visibleRange.start) && Date.parse(visibleRange.end)) {
            setLoading(true);
            ScheduleService.getAvailability({
                expId: experience.id,
                fromDateTime: new Date(visibleRange.start),
                toDateTime: new Date(visibleRange.end),
                groupSize: quantity,
                code,
            }).then((res) => {
                setSlots([...res]);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [experience, quantity, visibleRange]);

    return (
        <div
            className={clsx(
                !fullScreenMode && classes.calendar,
                !fullScreenMode && isScrolled ? classes.fixed : classes.normal
            )}
        >
            <ExperienceAvailabilityPageComponent
                showLoading={loading}
                showPrice={false}
                showAvailability={true}
                quantity={quantity}
                setQuantity={setQuantity}
                setDatePicker={setDatePicker}
                datePicker={datePicker}
                slots={slots}
                experience={experience}
                setSelectedSlot={(item) => {
                    setSelectedSlot(item);
                    addBookedExperienceWithQuantityPrice(
                        { ...experience, ...item },
                        item.price * quantity,
                        quantity
                    );
                    setTimeOfArrival(item.startUTC);
                    setDialogOpen(item)
                }}
                setVisibleRange={setVisibleRange}
            />
            <DialogCore
                isOpen={dialogOpen}
                onClose={handleCloseDialog}
                title=""
                content={
                    <ExperienceDetails
                        info={selectedSlot}
                        experienceName={experience?.name}
                        dateSlot={datePicker}
                        quantity={quantity}
                        experience={experience}
                    />
                }
            />
        </div>
    );
};

export default AvailabilityCalendar;
