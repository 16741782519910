import { useEffect } from 'react';
import WebFont from 'webfontloader';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider as MuiPickerProvider } from '@mui/x-date-pickers';

import theme from 'core/theme';
import Router from 'core/routing';

import { AuthProvider } from 'core/contexts/auth';
import { ModusesProvider } from 'core/contexts/modus';
import { LocationsProvider } from 'core/contexts/location';
import { CoTripersProvider } from 'core/contexts/coTriper';
import { ExperiencesProvider } from './core/contexts/experience';
import { FiltersProvider } from './core/contexts/filter';
import { LocalizationProvider } from 'core/contexts/localization';
import { PaymentProvider } from 'core/contexts/payment';
import { SortsProvider } from 'core/contexts/sort';
import { HostelProvider } from 'core/contexts/hostel';
import { PaggingProvider } from 'core/contexts/pagging';
import getDatepickerLocale from 'core/locales/getDatepickerLocale';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'antd/dist/antd.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const App = () => {
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Mina', 'Mina:bold'],
            },
        });
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <MuiPickerProvider dateAdapter={AdapterDateFns} adapterLocale={getDatepickerLocale()}>
                    <LocalizationProvider>
                        <AuthProvider>
                            <PaggingProvider>
                                <HostelProvider>
                                    <ModusesProvider>
                                        <LocationsProvider>
                                            <CoTripersProvider>
                                                <ExperiencesProvider>
                                                    <FiltersProvider>
                                                        <PaymentProvider>
                                                            <SortsProvider>
                                                                <Router />
                                                            </SortsProvider>
                                                        </PaymentProvider>
                                                    </FiltersProvider>
                                                </ExperiencesProvider>
                                            </CoTripersProvider>
                                        </LocationsProvider>
                                    </ModusesProvider>
                                </HostelProvider>
                            </PaggingProvider>
                        </AuthProvider>
                    </LocalizationProvider>
                    <ToastContainer />
                </MuiPickerProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

App.displayName = 'App';

export default App;
