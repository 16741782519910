import React from 'react';
import { Breadcrumbs, Link, Typography, useMediaQuery } from '@mui/material';

const PageIndicator = ({ pageStack, clickable = false }) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

    if (!pageStack || pageStack.length === 0) {
        return (<></>);
    }

    return (
        <Breadcrumbs maxItems={isMobile ? 2 : 8}>
            {pageStack.map((page, index) => {
                if (index === pageStack.length - 1 && !clickable) {
                    return (<Typography>{page.name}</Typography>);
                }

                return (<Link href={page.link}>{page.name}</Link>);
            })}
        </Breadcrumbs>
    );
};

export default PageIndicator;
