import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    calendar: {
        padding: '0.8rem',
        width: 'max-content',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '8px',
        backdropFilter: 'blur(10px)',
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
    },
    normal: {
        position: 'relative',
        top: 0,
    },
    fixed: {
        position: 'fixed',
        top: '1.5rem',
    },
}));

export default useStyles;
