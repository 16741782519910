import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  content: {
    flex: 1,
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
    padding: theme.spacing(0),
    [theme.breakpoints.down('lg')]: {
    },
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
    backgroundColor: 'white',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: (props) => props.drawerWidth,
  },
  contentMaxWidth: {
    maxWidth: '85%',
    margin: 'auto',
  },
}));
