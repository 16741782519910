import overrides from './overrides';
import palette from './palette';
import typography from './typography';
import { createTheme } from '@mui/material';

export default createTheme({
    palette,
    typography,
    overrides,
    inputs: {
        minHeight: 104,
    },
});
