import React, { useContext, useState, useEffect } from 'react';

const LocationsContext = React.createContext(null);

export const LocationsProvider = ({ children }) => {
    const [previousLocation, setPreviousLocation] = useState(null);

    return <LocationsContext.Provider value={{ previousLocation, setPreviousLocation }}>{children}</LocationsContext.Provider>;
};

LocationsProvider.displayName = 'LocationsProvider';

export const useLocations = () => useContext(LocationsContext);
