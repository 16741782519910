import { useState } from 'react';

import placeholder from 'assets/images/experiences/placeholder.png';

const useExperienceDetail = ({
    experienceService
}) => {
    const [loading, setLoading] = useState(false);
    const [experience, setExperience] = useState({});
    const [mediaUrls, setMediaUrls] = useState([{}]);
    const [contractName, setContractName] = useState('');

    const parseMediaUrls = (media) => {
        if (!media) {
            return;
        }

        if (media.length === 0) {
            setMediaUrls([
                {
                    type: 1,
                    src: placeholder,
                    alt: placeholder,
                },
            ]);
        }

        if (media.length > 0) {
            setMediaUrls(
                media.map((media) =>
                    media.type === 1
                        ? {
                            type: 1,
                            src: `${process.env.REACT_APP_CDN_URL}${media.src}`,
                            alt: media.alt,
                        }
                        : { type: 3, src: media.src }
                )
            );
        }
    }

    const getExperience = async (id) => {
        try {
            setLoading(true);
            const experience = await experienceService.getById(id);

            if (!experience) {
                throw new Error();
            }

            setExperience(experience);
            parseMediaUrls(experience.media);

            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const getContractName = async (code) => {
        try {
            const contractInfo = await experienceService.getByHostel(code, 0, [], []);

            if (!contractInfo) {
                throw new Error();
            }

            setContractName(contractInfo.establishmentData.name);

            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
        }
    }

    return {
        loading,
        experience,
        mediaUrls,
        contractName,
        getExperience,
        getContractName
    };
};

export default useExperienceDetail;
