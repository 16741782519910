import React, { useRef, useEffect, useCallback } from 'react';
import { CheckoutService } from 'core/api/services/checkout';
import { useExperiences } from 'core/contexts/experience';
import { useLocation } from 'react-router-dom';
import { useHostel } from 'core/contexts/hostel';
import { CircularIndeterminate } from '@utriper/core-ui';
import { useLocations } from 'core/contexts/location';

const Checkout = (props) => {
    const { code } = useHostel();
    const { bookedExperiences, timeOfArrival } = useExperiences();
    const { previousLocation } = useLocations();
    const location = useLocation();
    const experienceDetailId = location.state.experienceDetailId;
    const groupSize = location.state.groupSize;

    const hasRun = useRef(false);

    const createReservation = async () => {
        // Crear link de pago y redireccionar
        const booking = await CheckoutService.createLink({
            experiences: bookedExperiences,
            timeOfArrival,
            experienceDetailId,
            groupSize,
            code: parseInt(code, 16),
            callbackURL: `${window.location.origin}${previousLocation}`,
        });
        window.location.href = `${process.env.REACT_APP_UTRIPER_BUY_URL}/?code=${booking.paymentLink}`;
    };
    useEffect(() => {
        if (!hasRun.current) {
            createReservation();
            hasRun.current = true;
        }
    }, []);

    return (
        <>
            <CircularIndeterminate centered={true} styles={{ marginLeft: '4em' }} />
        </>
    );
};
export default Checkout;
