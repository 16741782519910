import axios from 'axios';
import { getSession } from 'core/api/session';
import { AuthService } from './services/auth';

const httpClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
});

export const Method = {
    GET: 'GET',
    PUT: 'PUT',
    PATCH: 'PATCH',
    POST: 'POST',
    DELETE: 'DELETE',
};

// TODO: implement refresh token interceptor
export const fetch = async (url, config) => {
    const { authenticated = false, ...options } = config;

    const request = {
        ...options,
        headers: {
            'Content-Type': 'application/json',
        },
        url,
    };
    try {
        if (authenticated) {
            let token = getSession();
            if (!token || token == null) {
                token = await AuthService.getToken();
            }
            request.headers.Authorization = `Bearer ${token}`;
        }

        const response = await httpClient.request(request);

        return response.data;
    } catch (error) {
        // check if token has expired & try again request
        if (error.request.status === 403) {
            const token = await AuthService.getToken();
            if (token) {
                request.headers.Authorization = `Bearer ${token}`;
                const response = await httpClient.request(request);
                return response.data;
            }
        }

        throw error;
    }
};
