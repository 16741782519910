import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/material';

import Layout from 'components/Layout';
import Header from 'components/Hostels/Header';
import ExperiencesList from 'core/libs/core-ui/components/ExperienceList';

import { useExperiences } from 'core/contexts/experience';
import { useHostel } from 'core/contexts/hostel';
import { useFilters } from 'core/contexts/filter';
import { useSorts } from 'core/contexts/sort';
import { useTranslation } from 'core/contexts/localization';

import { ExperienceService } from 'core/api/services/experiences';

const Template = () => {
    const { qrExperiences, getHostelExperiences } = useExperiences();
    const { priceOrder } = useSorts();
    const { tourOperatorsId, moduses } = useFilters();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const code = params.get('code');
    const history = useHistory();
    const { setCode, sectionId, setSectionId, data } = useHostel();

    useEffect(() => {
        if (code != null) {
            setCode(code);
          } else {
            history.replace('/');
          }
        }, [code]);
        
        useEffect(() => {
          if (code != null) {
            getHostelExperiences(code, priceOrder, moduses, tourOperatorsId, sectionId);
        }
    }, [priceOrder, moduses, code, tourOperatorsId, sectionId ]);

    return (
        <Layout>
            <Header />
            <Box
                sx={{
                    backgroundImage:
                        'url("/static/media/landingbg.f640a989.jpg")',
                    mt: 2,
                }}>
                <ExperiencesList
                    code={code}
                    contractExperiences={qrExperiences}
                    useTranslation={useTranslation}
                    experienceService={ExperienceService}
                />
            </Box>
        </Layout>
    );
};
export default Template;
