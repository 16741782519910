import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import {
    Grid2,
    useMediaQuery,
} from '@mui/material';

import Dialog from 'core/libs/core-ui/components/Dialog';
import FullScreenImage from 'core/libs/core-ui/components/FullScreenImage';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';

import { BookingButton, ExperienceGrid } from './components';
import useStyles from './ExperiencePage.styles';
import useExperienceDetail from './ExperiencePage.hooks';

const ExperiencePage = ({
    experienceId,
    contractCode,
    useTranslation,
    experienceService
}) => {
    const classes = useStyles();
    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const { t: translate } = useTranslation();

    const [error, setError] = useState('');

    const [openInFullScreen, setOpenInFullScreen] = useState(false);
    const [sliderIndex, setSliderIndex] = useState(0);

    const {
        loading,
        experience,
        mediaUrls,
        contractName,
        getExperience,
        getContractName,
    } = useExperienceDetail({
        experienceService
    });

    const validate = () => {
        if (!experienceId) {
            setError(translate('experience.error.invalidId'));
        }
    };

    const getSliderSrc = () => {
        return mediaUrls[sliderIndex].src;
    };

    const handleOpenInFullScreen = () => {
        setOpenInFullScreen(true);
    };

    const handleCloseInFullScreen = () => {
        setOpenInFullScreen(false);
    };

    useEffect(() => {
        validate();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        getExperience(experienceId);
    }, []);

    useEffect(() => {
        if (!contractCode) {
            return;
        }

        getContractName(contractCode);
    }, [])

    useEffect(() => {
        if (!error || error === '') {
            return;
        }

        toast.error(error, {
            position: 'top-right',
            theme: 'colored',
        });

        setTimeout(() => {
            window.location.replace(window.location.origin);
        }, "2000");
    }, [error]);

    return (
        <div className={classes.root}>
            {(loading || Object.keys(experience).length === 0) ? (
                <CircularIndeterminate />
            ) : (
                <Grid2 container spacing={2}>
                    <Grid2 size={{xs: 12, md: 8, xl: 10}}>
                        <ExperienceGrid
                            experience={experience}
                            contractCode={contractCode}
                            contractName={contractName}
                            mediaUrls={mediaUrls}
                            useTranslation={useTranslation}
                            useSliderIndex={{ sliderIndex, setSliderIndex }}
                            handleOpenInFullScreen={handleOpenInFullScreen}
                        />
                    </Grid2>
                    <Grid2 size={{xs: 12, md: 4, xl: 2}} className={classes.buttonWrapper}>
                        <BookingButton
                            showCalendar={isLargeScreen}
                            experience={experience}
                            contractCode={contractCode}
                        />
                    </Grid2>
                    <Dialog
                        open={openInFullScreen}
                        handleClose={handleCloseInFullScreen}
                        hideActions={true}
                        hideTitle={true}
                        dialogContent={
                            <FullScreenImage
                                src={getSliderSrc()}
                                onClose={handleCloseInFullScreen}
                            />
                        }
                    />
                </Grid2>
            )}
        </div>
    );
};

export default ExperiencePage;
