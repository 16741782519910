import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { node, string } from 'prop-types';
import clsx from 'clsx';
import { useLocations } from 'core/contexts/location';
import AppBar from 'components/AppBar';
import Footer from 'components/Footer';
import { useLayout } from './hook';

const Layout = ({ children, contentClassName, showUtriperButtons = false, setMaxWidth = true }) => {
    const location = useLocation(); // Get the current location
    const { classes, open, toggleMenu, saved } = useLayout();
    const { setPreviousLocation } = useLocations();
    useEffect(() => {
        setPreviousLocation(`${location.pathname}${location.search}`);
    }, [location]);

    return (
        <div className={classes.wrapper}>
            <AppBar open={open} toggleMenu={toggleMenu} saved={saved} showUtriperButtons={showUtriperButtons} />
            {/* <Menu open={open} toggleMenu={toggleMenu} /> */}
            <main className={clsx(classes.content, open && classes.contentShift, setMaxWidth && classes.contentMaxWidth, contentClassName)}>{children}</main>
            <Footer />
        </div>
    );
};

Layout.propTypes = {
    children: node.isRequired,
    contentClassName: string,
};

Layout.displayName = 'Layout';

export default Layout;
