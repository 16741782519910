import React, { useContext, useState } from 'react';

import { ExperienceService } from '../api/services/experiences';
import { useHostel } from './hostel';
import { usePagging } from './pagging';
import { NumberParam, useQueryParam } from 'use-query-params';

const ExperiencesContext = React.createContext(null);

export const ExperiencesProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [experiences, setExperiences] = useState([]);
  const [qrExperiences, setQrExperiences] = useState([]);
  const [bookedExperiences, setBookedExperiences] = useState([]);
  const [timeOfArrival, setTimeOfArrival] = useState(null);
  const { setData, sectionId, setSectionId } = useHostel();


 

  const search = window.location.search
  const params = new URLSearchParams(search);
  const paramsSectionId = params.get('sectionId')
  

  const { setHasMorePages } = usePagging();

  // console.log('ExperienceService', ExperienceService);
  const getExperiences = async (filters, page, resetExperiences = false) => {
    try {
      setLoading(true);
      const experiencesData = await ExperienceService.get(filters, page);

      if (!experiencesData) {
        throw new Error();
      }
      console.log("experiences =======> ", [...experiences, ...experiencesData.experiences])
      setHasMorePages(experiencesData.hasMorePages);

      if (resetExperiences) {
        setExperiences(experiencesData.experiences);
      } else {
        setExperiences([...experiences, ...experiencesData.experiences]);
      }
      // setExperiences(experiencesData);

      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const getHostelExperiences = async (code, priceOrder, modusesFilter, tourOperatorsId) => {
    try {
      console.log("context getHostelExperiences");
      setLoading(true);
      let qrData = await ExperienceService.getByHostel(code, priceOrder, modusesFilter, tourOperatorsId, paramsSectionId);

      const defaultSection = Number(paramsSectionId) || qrData?.establishmentData.contractSectionId || null
      //load preferences: first what was sent on params, second defined on contract, third none

      setSectionId(defaultSection)
      if(paramsSectionId === null && qrData?.establishmentData.contractSectionId){
        qrData = await ExperienceService.getByHostel(code, priceOrder, modusesFilter, tourOperatorsId, defaultSection);
      }

      if (!qrData) {
        throw new Error();
      }

      setData(qrData.establishmentData);
      
      setQrExperiences(qrData.experiences);
  
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  }
  

  // today we only manage one experience, not a cart of experiences
  const addBookedExperience = (experience) => {
    setBookedExperiences([experience]);
    // setBookedExperiences([...bookedExperiences, experience]);
  }

  const addBookedExperienceWithPrice = (experience, price) => {
    experience.price = price;
    setBookedExperiences([experience]);
    // setBookedExperiences([...bookedExperiences, experience]);
  }

  const addBookedExperienceWithQuantityPrice = (experience, price, quantity) => {
    experience.priceTotal = price;
    experience.quantity = quantity;
    setBookedExperiences([experience]);
    // setBookedExperiences([...bookedExperiences, experience]);
  }

  const setBookedExperiencePrice = (experienceId, price) => {
    console.log(bookedExperiences, experienceId, price);
    const index = bookedExperiences.findIndex(exp => exp.id == experienceId);
    if (index === -1) {
      // TODO: error
    }
    const auxBookedExperience = [...bookedExperiences];
    auxBookedExperience[index].price = price;
    setBookedExperiences(auxBookedExperience);
  }

  const hasBookedExperiences = () => {
    return bookedExperiences.length > 0;
  }

  return (
    <ExperiencesContext.Provider
      value={{
        loading,
        getExperiences,
        getHostelExperiences,
        experiences,
        addBookedExperience,
        addBookedExperienceWithPrice,
        bookedExperiences,
        setBookedExperiencePrice,
        hasBookedExperiences,
        timeOfArrival,
        setTimeOfArrival,
        qrExperiences,
        addBookedExperienceWithQuantityPrice,
      }}>
      {children}
    </ExperiencesContext.Provider>
  );
};

ExperiencesProvider.displayName = 'ExperiencesProvider';

export const useExperiences = () => useContext(ExperiencesContext);
