import React from 'react';
import { Box, List, ListItem, Typography } from '@mui/material';

import { EXPERIENCE_DATA_TYPE } from 'core/libs/constants';
import TimeIcon from 'core/libs/core-ui/assets/icons/experience/time.svg';
import EnvironmentIcon from 'core/libs/core-ui/assets/icons/experience/environment.svg';
import CancellationPolicyIcon from 'core/libs/core-ui/assets/icons/experience/cancel.svg';
import IncludesPolicyIcon from 'core/libs/core-ui/assets/icons/experience/check.svg';

import {
    getTranslatedNameFromNumber as getEnvironmentTranslatedNameFromNumber,
    environmental_policies,
} from 'core/libs/import/EnvironmentalPolicies';
import {
    getTranslatedNameFromNumber as getCancellationPolicyTranslatedNameFromNumber,
    cancellation_policies,
} from 'core/libs/import/CancellationPolicies';
import {
    getTranslatedNameFromNumber as getIncludesPolicyTranslatedNameFromNumber,
    includes_policies,
} from 'core/libs/import/IncludesPolicies';

import ModusChip from 'core/libs/core-ui/components/Chip';

import useStyles from './ExperiencePolicies.styles';

const ExperiencePolicies = ({ experience, lang, translate }) => {
    const classes = useStyles();

    const RenderIcon = ({ icon }) => {
        return (
            <Box component="img" src={icon} alt="Icon" width={20} height={20} />
        );
    };

    const formatEnvironmentalPolicies = (icon, number) => {
        console.log('formatEnvironmentalPolicies number : ', number);
        if (number === 0) {
            return null;
        } else {
            const policyName = getEnvironmentTranslatedNameFromNumber(
                environmental_policies,
                number,
                lang
            );

            if (!policyName || policyName.length === 0) {
                return;
            }

            return (
                <Box display="flex" alignItems="center">
                    <RenderIcon icon={icon} />
                    <List className={classes.experienceRecycleWrapper}>
                        {policyName.map((name, index) => (
                            <ListItem
                                className={classes.experienceRecycle}
                                key={index}>
                                {/* {name} */}
                                <ModusChip
                                    key={name}
                                    color="gray"
                                    className={classes.modusChip}
                                    label={name}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            );
        }
    };

    const formatCancellationPolicies = (icon, number) => {
        if (number === 0) {
            return '';
        } else {
            const cancellationName =
                getCancellationPolicyTranslatedNameFromNumber(
                    cancellation_policies,
                    number,
                    lang
                );
            return (
                <Box display="flex" alignItems="center">
                    <RenderIcon icon={icon} />
                    <List className={classes.experienceCancellationWrapper}>
                        {cancellationName.map((name, index) => (
                            <ListItem
                                className={classes.experienceCancellation}
                                key={index}>
                                {name}
                            </ListItem>
                        ))}
                    </List>
                </Box>
            );
        }
    };

    const formatIncludesPolicies = (icon, number) => {
        if (number === 0) {
            return '';
        } else {
            let prefix = translate('libs.includes');
            const policyName = getIncludesPolicyTranslatedNameFromNumber(
                includes_policies,
                number,
                lang
            );

            if (!policyName || policyName.length === 0) {
                return;
            }

            return (
                <Box display="flex" alignItems="center">
                    <RenderIcon icon={icon} />
                    <List className={classes.experienceIncludeWrapper}>
                        {policyName.map((name, index) => (
                            <ListItem
                                className={classes.experienceInclude}
                                key={index}>
                                {/* {`${prefix} ${name}`} */}
                                <ModusChip
                                    key={name}
                                    color="gray"
                                    className={classes.modusChip}
                                    label={name}
                                />
                            </ListItem>
                        ))}
                    </List>
                    {/* <Box ml={2}>{`${prefix} ${policyName}`}</Box> */}
                </Box>
            );
        }
    };

    const formatAnticipationPolicy = (icon, number) => {
        let days = Math.floor(number / 1440);
        let hours = Math.floor((number - days * 1440) / 60);
        let minutes = number - days * 1440 - hours * 60;

        let anticipationText = '';
        if (days > 0)
            anticipationText += `${days} ${translate('libs.time.days')} `;
        if (hours > 0)
            anticipationText += `${hours} ${translate('libs.time.hours')} `;
        if (minutes > 0)
            anticipationText += `${minutes} ${translate('libs.time.minutes')} `;

        if (anticipationText === '') {
            anticipationText = translate('libs.anticipation.none');
        } else {
            anticipationText = `${translate(
                'libs.anticipation.prefix'
            )} ${anticipationText.trim()} ${translate(
                'libs.anticipation.suffix'
            )}`;
        }

        return (
            <Box display="flex" alignItems="center">
                <RenderIcon icon={icon} />
                <Box sx={{ml: 1}}>{anticipationText}</Box>
            </Box>
        );
    };

    const getPolicyIconRender = (icon, number, experienceDataType) => {
        if (!number || number.length === 0) {
            return (<></>);
        }

        switch (experienceDataType) {
            case EXPERIENCE_DATA_TYPE.ENVIRONMENTAL_POLICIES:
                return formatEnvironmentalPolicies(icon, number);
            case EXPERIENCE_DATA_TYPE.CANCELLATION_POLICIES:
                return formatCancellationPolicies(icon, number);
            case EXPERIENCE_DATA_TYPE.INCLUDES_POLICIES:
                return formatIncludesPolicies(icon, number);
            case EXPERIENCE_DATA_TYPE.ANTICIPATION:
                return formatAnticipationPolicy(icon, number);
            default:
                throw new Error('Invalid experienceDataType');
        }
    };

    const getCancellationPoliciesList = (additionalCancellationPolicies) => {
        const cancellationPolicies = additionalCancellationPolicies.map(
            (cancellationPolicy) => {
                let anticipationTimeText = '';
                const time = cancellationPolicy.time;
                const days = Math.floor(time / 1440);
                const hours = Math.floor((time - days * 1440) / 60);

                if (days > 0) {
                    anticipationTimeText = `${days} ${translate(
                        'libs.time.days'
                    )}`;
                }

                if (hours > 0) {
                    if (days > 0) {
                        anticipationTimeText += ` ${translate(
                            'libs.and'
                        )} ${hours} ${translate('libs.time.hours')}`;
                    } else {
                        anticipationTimeText = `${hours} ${translate(
                            'libs.time.hours'
                        )}`;
                    }
                }

                return (
                    <Typography variant="body2" component="p">
                        <ListItem className={classes.experienceCancellation}>
                            {`${cancellationPolicy.percentage}%
                             ${translate(
                                'cancellation_policies.ofTheValuePaidWith'
                            )}
                              ${anticipationTimeText} ${translate(
                                'cancellation_policies.ofAnticipation'
                            )}`}
                        </ListItem>
                    </Typography>
                );
            }
        );

        cancellationPolicies.unshift(
            <ListItem className={classes.experienceCancellation}>
                <Typography variant="body2" component="p">
                    <List className={classes.experienceCancellation}>
                        {translate('cancellation_policies.title')}:
                    </List>
                </Typography>
            </ListItem>
        );

        return (
            <Box
                display="flex"
                sx={{mt: 1}}
                className={classes.experienceCancellation}>
                <RenderIcon alignSelf="start" icon={CancellationPolicyIcon} />
                <ListItem className={classes.experienceCancellation}>
                    <List className={classes.experienceCancellation}>
                        {cancellationPolicies}
                    </List>
                </ListItem>
            </Box>
        );
    };

    return (
        <Box>
            {getPolicyIconRender(
                TimeIcon,
                experience.anticipation,
                EXPERIENCE_DATA_TYPE.ANTICIPATION
            )}
            {getPolicyIconRender(
                EnvironmentIcon,
                experience.envPolicies,
                EXPERIENCE_DATA_TYPE.ENVIRONMENTAL_POLICIES
            )}
            {experience?.cancelPolicies?.nonRefundable
                ? getPolicyIconRender(
                    CancellationPolicyIcon,
                    8,
                    EXPERIENCE_DATA_TYPE.CANCELLATION_POLICIES
                )
                : getCancellationPoliciesList(
                    experience.cancelPolicies.additionals
                )}
            {getPolicyIconRender(
                IncludesPolicyIcon,
                experience.includesPolicies,
                EXPERIENCE_DATA_TYPE.INCLUDES_POLICIES
            )}
        </Box>
    );
};

export default ExperiencePolicies;
