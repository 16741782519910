import React from 'react';

import { Box, Typography } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ContactIcon from '@mui/icons-material/Contacts';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import { MoneyFormattedUtils } from 'core/libs/core-ui/utils/moneyFormatted';

import ModusList from '../ModusList';
import { useStyles } from './ExperienceHeader.styles';

const ExperienceHeader = ({ experience, useTranslation }) => {
    const classes = useStyles();
    const { t: translate, i18n } = useTranslation();

    return (
        <>
            <Box style={{ position: "relative" }}>
                <Typography
                    className={classes.experiencieTitle}
                    variant="h3">
                    {experience.name}
                </Typography>
                <Typography
                    className={classes.experiencieSubtitle}
                    variant="h5">
                    {experience.subtitle}
                </Typography>
            </Box >
            {experience.experiencerName &&
                (<Box display="flex" sx={{mb: 1}}>
                    <ContactIcon fontSize="small" />
                    <Box alignSelf="center">
                        <Typography
                            className={classes.experiencerName}>
                            {experience.experiencerName}
                        </Typography>
                    </Box>
                </Box>)}
            {experience.location &&
                (<Box className={classes.locationWrapper} sx={{mb: 1}}>
                    <LocationOnIcon />
                    <Box>{experience.location}</Box>
                </Box>)}
            {experience && experience.moduses &&
                (<Box display="flex">
                    <ModusList
                        experience={experience}
                        lang={i18n.language}
                    />
                </Box>)}
            {experience.fromPrice &&
                (<Box display="flex">
                    <Box>
                        <AttachMoneyIcon />
                    </Box>
                    <Box>
                        <Typography variant="body2" component="p">
                            <Typography variant="span">
                                {`${translate(
                                    'experienceCard.fromPrice'
                                )} ${experience.currency} ${MoneyFormattedUtils(
                                    experience.fromPrice,
                                    i18n.language
                                )}`}
                            </Typography>
                            <Typography
                                className={classes.perPersonWrapper}>
                                {translate(
                                    'experienceCard.perPerson'
                                )}
                            </Typography>
                            {/* <p style={{ display: "inline", fontWeight: "bold" }}> {`${props.payNow}% ${translate("experienceCard.in_advance")}`}</p> */}
                        </Typography>
                    </Box>
                </Box>)}
        </>
    );
};

export default ExperienceHeader;
