import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    gridDetailsWrap: {
        display: "flex",
        [theme.breakpoints.down('lg')]: {
            marginTop: "0.5rem"
        },
    },
    copyLinkButton: {
        [theme.breakpoints.down('lg')]: {
            marginLeft: "auto"
        },
    },
    experienceDescription: {
        whiteSpace: "pre-wrap",
    },
}));

export default useStyles;
