import React from 'react';
import {
    useQueryParam,
    NumberParam,
    StringParam,
} from 'use-query-params';

import ExperiencePage from 'core/libs/core-ui/pages/ExperiencePage';

import Layout from 'components/Layout';
import { useTranslation } from 'core/contexts/localization';
import { ExperienceService } from 'core/api/services/experiences';

const Experience = () => {
    const [queryId, setQueryId] = useQueryParam('id', NumberParam);
    const [queryCode, setQueryCode] = useQueryParam('code', StringParam);

    return (
        <Layout>
            <ExperiencePage
                experienceId={queryId}
                contractCode={queryCode}
                useTranslation={useTranslation}
                experienceService={ExperienceService}
            />
        </Layout>
    );
};
export default Experience;
