import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "2rem",
        [theme.breakpoints.down('lg')]: {
            margin: 0,
            marginTop: "3rem",
            marginBottom: "1rem"
        },
    },
    buttonWrapper: {
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            marginTop: "0.8rem"
        },
    },
}));

export default useStyles;
