import React from 'react';
import { Button } from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

import { Order } from "core/contexts/sort";

const SortButton = ({ text, onClick, orderBy, style }) => {

   

    return (
        <React.Fragment>
            <style>
                {`.MuiButton-root {
                        text-transform: none;
                    }
                `}
            </style>
            <Button
                style={{ ...style}}
                variant="outlined"
                // endIcon={orderBy == Order.ASCENDANT ? <ArrowDownward /> : <ArrowUpward />}
                onClick={onClick}
            >
               <strong style={{fontSize: '14px', fontFamily: 'cursive'}}>$</strong> { orderBy === Order.ASCENDANT ? <ArrowDownward sx={{height: '20px', fontWeight:' 700'}} /> : <ArrowUpward  sx={{height: '20px', fontWeight:' 700'}}  />  }
            </Button>
        </React.Fragment>
    );
}

export default SortButton;