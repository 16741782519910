import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    modusesWrapper: {
        display: "flex",
        flexWrap: "wrap",
    },
    modusChip: {
        margin: "0.3rem",
        fontSize: "0.7rem",
    },
    checkIcon: {},
}));

export default useStyles;
